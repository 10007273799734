// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import LoadingIndicator from '../LoadingIndicator';
import Headline from '../Headline';
import DashboardTiles from '../Home/DashboardTiles';

import type MasterStore from '../../stores/MasterStore';
import type TilesStore from '../../stores/TilesStore';
import track from '../../tracking';

type Props = {
  masterStore: MasterStore,
  tilesStore: TilesStore,
};

type State = {
  forceReminderView: boolean,
};

@inject('masterStore', 'tilesStore', 'authStore')
@observer
export default class PageHome extends Component<Props, State> {
  static breadcrumb = 'Meine Reise';

  state = {
    forceReminderView: false,
  };

  componentDidMount() {
    this.props.tilesStore.fetchAll();

    if (this.props.authStore.user.backLink) {
      window.location.href = this.props.authStore.user.backLink;
    }

    track.pageView(window.location.pathname, ['Startseite']);
  }

  @autobind
  changeState() {
    this.setState({
      forceReminderView: true,
    });
  }

  render() {
    const { masterStore, tilesStore, authStore } = this.props;
    const { user, masterData } = masterStore;

    if (!masterData || tilesStore.isLoading) return <LoadingIndicator />;

    return (
      <div className="page">
        {this.state.forceReminderView}
        <Headline
          className="l-mod"
          title={`Willkommen ${user.displayName}`}
          subtitle={masterData.subtitleLine}
          subsubtitle={<i>{masterData.ship}</i>}
        />

        <DashboardTiles
          tiles={tilesStore.tiles}
          bonus={masterData.bonusText}
          travelParty={masterData.travelParty}
          masterData={masterData}
          callback={this.changeState}
        />
      </div>
    );
  }
}
