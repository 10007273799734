export const isLocal =
  window.location.hostname.indexOf('local.dev.cellular.de') >= 0 ||
  window.location.href.indexOf('http://local.dev.cellular.de:3000') >= 0 ||
  window.location.href.indexOf('http://local.dev.cellular.de:3005') >= 0 ||
  window.location.href.indexOf('http://local.dev.cellular.de:3050') >= 0;

export const isDev = window.location.hostname.indexOf('tuic-mrl.dev.cellular.de') >= 0;

export const isTest = window.location.hostname.indexOf('meinereise.test.meinschiff.com') >= 0;

export const isProduction = window.location.hostname.indexOf('meinereise.meinschiff.com') >= 0;

/**
 * Get the current environment the app is running in using the hostname.
 *
 * Possible values are:
 * - "local" - if running locally
 * - "dev" - if running in dev environment
 * - "test" - if running in test environment
 * - "production" - if running in production environment
 * - "unknown" - if the environment could not be determined by the hostname.
 *
 * @returns one of the environment strings mentioned above
 */
export const getEnvironment = () => {
  switch (true) {
    case isProduction:
      return 'production';
    case isTest:
      return 'test';
    case isDev:
      return 'dev';
    case isLocal:
      return 'local';
    default:
      return 'unknown';
  }
};
